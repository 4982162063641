import dynamic from 'next/dynamic';
import { ListingSearchPanel } from '@/types';
import { useIsTemplate } from '@/lib/hooks/useApp';

const StaticSearchPanel = dynamic(() => import('./StaticPanel'));
const DynamicSearchPanel = dynamic(() => import('./DynamicPanel'));

/*
 * This block needs to work with and without SSR. In a way.
 * On /osta it works on SSR where the data comes in straight away based on query params
 * On frontpage it just does the form action to /osta
 * On campaign pages, it gets the data based on CMS query params and then further queries are on client side
 */

const SearchPanel = (props: ListingSearchPanel) => {
  const isFrontPage = useIsTemplate('front-page');
  if (isFrontPage) {
    return <StaticSearchPanel {...props} />;
  }

  return <DynamicSearchPanel {...props} />;
};

export default SearchPanel;
